import React, { useState } from "react"
import { changeLocale, IntlContextConsumer } from "gatsby-plugin-intl"
import "./LanguageSwitch.scss"
import FlagHU from "./flag_hungary.svg"
// import FlagEN from "./flag_uk.svg"
import FlagRO from "./flag_romania.svg"

const FLAGS = {
  // en: FlagEN,
  hu: FlagHU,
  ro: FlagRO,
}

const LANGS = {
  en: {
    hu: "Hungarian",
    ro: "Romanian",
    // en: "English",
  },
  hu: {
    hu: "Magyar",
    ro: "Román",
    // en: "Angol",
  },
  ro: {
    hu: "Maghiară",
    ro: "Română",
    // en: "Engleză",
  },
}

const LanguageSwitch = () => {
  const [langShow, setLangShow] = useState("")

  const RenderMobile = ({ languages, currentLocale }) => (
    <p
      className="d-flex text-center mb-0 mt-3 px-5 text-uppercase"
      id="mobile-switcher"
    >
      {languages.map(lang => (
        <span
          key={lang}
          className={`flex-grow-1 mt-3 mx-2 py-2 ${
            currentLocale === lang ? "selected-language" : ""
          }`}
          onClick={() => changeLocale(lang)}
        >
          <img src={FLAGS[lang]} alt={LANGS[lang][lang]} />
        </span>
      ))}
    </p>
  )

  const RenderDesktop = ({ languages, currentLocale }) => (
    <div className="dropdown" id="desktop-switcher">
      {/* eslint-disable-next-line */}
      <a
        className="nav-item nav-link dropdown-toggle"
        href="#"
        onClick={() => setLangShow(langShow ? "" : "show")}
      >
        <img
          src={FLAGS[currentLocale]}
          alt={LANGS[currentLocale][currentLocale]}
        />
        <span>{LANGS[currentLocale][currentLocale]}</span>
      </a>
      <div className={`dropdown-menu dropdown-menu-right ` + langShow}>
        {languages.map(lang => (
          // eslint-disable-next-line
          <a
            key={lang}
            className={`dropdown-item ${
              currentLocale === lang ? "active" : ""
            }`}
            href="#"
            onClick={() => changeLocale(lang)}
          >
            {LANGS[currentLocale][lang]}
          </a>
        ))}
      </div>
    </div>
  )

  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => (
        <>
          <RenderMobile languages={languages} currentLocale={currentLocale} />
          <RenderDesktop languages={languages} currentLocale={currentLocale} />
        </>
      )}
    </IntlContextConsumer>
  )
}

export default LanguageSwitch
