export default {
  container: {
    padding: "0.6em",
    maxWidth: 400,
    backgroundColor: "#91a2ec",
    borderRadius: 5,
    left: "0.75rem",
  },
  button: {
    minWidth: 200,
    color: "#11238c",
    backgroundColor: "#fff",
    borderRadius: 5,
    textAlign: "center",
    padding: ".4em .8em",
    margin: "0 auto",
    fontSize: 16,
  },
  link: {
    color: "#fff",
    opacity: 0.8,
    textDecoration: "underline",
  },
}
