import React from "react"
import CookieConsent from "react-cookie-consent"
import { getGDPRLink } from "../../utils"
import { FormattedMessage, injectIntl, Link } from "gatsby-plugin-intl"
import styles from "./CookieWindow.styles"

const CookieWindow = ({ intl }) => {
  console.log("intl", intl)
  return (
    <CookieConsent
      location="bottom"
      buttonText={intl.formatMessage({ id: "cookie.accept" })}
      cookieName="websitecookies"
      style={styles.container}
      buttonStyle={styles.button}
      expires={365}
      classname="cookie-window"
    >
      <FormattedMessage id="cookie.text">
        {txt => (
          <p>
            {txt + " "}
            <FormattedMessage id="cookie.link">
              {linkTxt => (
                <a
                  target="_blank"
                  style={styles.link}
                  rel="noopener noreferrer"
                  className="text-primary"
                  href={getGDPRLink(intl.locale)}
                >
                  {linkTxt}
                </a>
              )}
            </FormattedMessage>
          </p>
        )}
      </FormattedMessage>
    </CookieConsent>
  )
}

export default injectIntl(CookieWindow)
