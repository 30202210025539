import axios from "axios"
import queryString from "query-string"

export const createContact = ({
  type,
  email,
  phone,
  name = "",
  languages = "",
  license = "",
  prizeChecked = false,
  city = "",
  company = "",
  lang = "ro",
}) => {
  const domain = "/.netlify/functions/create-contact"

  let query

  if (type === "freelancer") {
    query = queryString.stringify({
      name,
      lang,
      email,
      phone,
      languages,
      license,
      prize: prizeChecked,
      type,
    })
  } else {
    query = queryString.stringify({
      company,
      lang,
      email,
      phone,
      city,
      type,
    })
  }

  return axios.get(`${domain}?${query}`)
}

export const searchOffices = ({ search = "" }) => {
  const domain = "/.netlify/functions/search-offices"

  const query = queryString.stringify({
    search,
  })

  return axios.get(`${domain}?${query}`)
}

export const emailIsValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

export const getTermsLink = currentLocale =>
  currentLocale === "hu" ? "/traduc_hu_terms.htm" : "/traduc_ro_terms.htm"

export const getGDPRLink = currentLocale =>
  currentLocale === "hu" ? "/traduc_hu_gdpr.htm" : "/traduc_ro_gdpr.htm"

export const getActAditionalLink = currentLocale =>
  currentLocale === "hu"
    ? "/traduc_ro_aditional.htm"
    : "/traduc_ro_aditional.htm"
