import React, { useState } from "react"
import { IntlContextConsumer, Link, FormattedMessage } from "gatsby-plugin-intl"
import "./Nav.scss"
import LanguageSwitch from "./LanguageSwitch"
import LogoRO from "./logo_ro.svg"

const Nav = () => {
  const [menuShow, setMenuShow] = useState("")
  const btnClass = menuShow ? "navbar-toggler" : "navbar-toggler collapsed"

  const onMenuToggle = () => {
    setMenuShow(menuShow ? "" : "show")
  }

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <div className="nav-container">
          <div className="container py-2">
            <nav className="navbar navbar-expand-md navbar-dark">
              <Link className="navbar-brand" to="/">
                <img src={LogoRO} alt="Logo" />
              </Link>
              <button
                className={btnClass}
                type="button"
                onClick={() => onMenuToggle()}
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className={
                  `collapse navbar-collapse justify-content-sm-center pr-md-5 ` +
                  menuShow
                }
              >
                <div className="navbar-nav pt-3">
                  <a
                    className="nav-item nav-link d-none d-md-block"
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://app.traduc.ro/auth/login?lng=${currentLocale}`}
                  >
                    <FormattedMessage id="buttons.login" />
                  </a>
                  <LanguageSwitch />
                </div>
              </div>
            </nav>
          </div>
        </div>
      )}
    </IntlContextConsumer>
  )
}

export default Nav
