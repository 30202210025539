import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import imageURL from "../../images/favicon.png"

const SEO = ({ description, keywords, title, opengraph }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            url
            title
            description
            keywords
            image
            imageWidth
            imageHeight
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaKeywords = keywords || site.siteMetadata.keywords

  const { ogUrl, ogTitle, ogImage, ogImageWidth, ogImageHeight } = opengraph

  return (
    <Helmet title={title} titleTemplate={`%s | ${site.siteMetadata.title}`}>
      <html lang="hu" />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@traduc" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageURL} />

      <meta property="og:url" content={ogUrl || site.siteMetadata.url} />
      <meta property="og:title" content={ogTitle || site.siteMetadata.title} />
      <meta property="og:image" content={ogImage || site.siteMetadata.image} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={title} />
      <meta
        property="og:image:width"
        content={ogImageWidth || site.siteMetadata.imageWidth}
      />
      <meta
        property="og:image:height"
        content={ogImageHeight || site.siteMetadata.imageHeight}
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  opengraph: {
    ogUrl: null,
    ogTitle: null,
    ogImage: null,
    ogImageWidth: null,
    ogImageHeight: null,
  },
}

export default SEO
