import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage, IntlContextConsumer } from "gatsby-plugin-intl"
import { getGDPRLink, getTermsLink, getActAditionalLink } from "../../utils"
import "./Footer.scss"

const Footer = () => {
  const { diaspora, eurologo } = useStaticQuery(graphql`
    query FooterImages {
      diaspora: file(relativePath: { eq: "footer/diaspora-logo.png" }) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      eurologo: file(relativePath: { eq: "footer/eu_ro-logo.png" }) {
        childImageSharp {
          fixed(width: 280) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <footer>
          <div className="footer--overlay" />
          <div className="footer--top py-4">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-5">
                  <FormattedMessage id="footer.about.heading">
                    {txt => <h3 className="mt-4">{txt}</h3>}
                  </FormattedMessage>
                  <FormattedMessage id="footer.about.description">
                    {txt => <p className="py-3 mb-0">{txt}</p>}
                  </FormattedMessage>
                </div>
                <div className="col-12 col-sm-6 offset-lg-1">
                  <FormattedMessage id="footer.contact.heading">
                    {txt => <h3 className="mt-4">{txt}</h3>}
                  </FormattedMessage>
                  <ul className="features py-3">
                    <FormattedMessage id="footer.contact.phone" tagName="li" />
                    <FormattedMessage id="footer.contact.email" tagName="li" />
                    <FormattedMessage id="footer.contact.facebook">
                      {txt => (
                        <li>
                          <a
                            href="https://www.facebook.com/traduc.ro"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white"
                          >
                            {txt}
                          </a>
                        </li>
                      )}
                    </FormattedMessage>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer--bottom py-5 py-lg-3">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-2 col-lg-3 px-sm-0 col-xl-3">
                  <FormattedMessage id="footer.bottom.copyright">
                    {txt => <h5 className="mb-lg-0">{txt}</h5>}
                  </FormattedMessage>
                </div>
                <div className="col-12 col-md-1 col-lg-3 col-xl-4">
                  <FormattedMessage id="footer.bottom.terms">
                    {txt => (
                      <p className="mb-lg-0">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary"
                          href={getTermsLink(currentLocale)}
                        >
                          {txt}
                        </a>
                      </p>
                    )}
                  </FormattedMessage>
                </div>
                <div className="col-12 col-md-2">
                  <FormattedMessage id="footer.bottom.data">
                    {txt => (
                      <p className="mb-lg-0">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary"
                          href={getGDPRLink(currentLocale)}
                        >
                          {txt}
                        </a>
                      </p>
                    )}
                  </FormattedMessage>
                </div>
                <div className="col-12 col-md-2">
                  <FormattedMessage id="footer.bottom.aditional">
                    {txt => (
                      <p className="mb-lg-0">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary"
                          href={getActAditionalLink(currentLocale)}
                        >
                          {txt}
                        </a>
                      </p>
                    )}
                  </FormattedMessage>
                </div>
                <div className="col-12 col-md-5 pt-md-4">
                  <Img
                    fixed={eurologo.childImageSharp.fixed}
                    alt="Uniunea Europeana, Guvernul Romaniei, Instrumente Structurale"
                  />
                </div>
                <div className="col-12 col-md-2 pt-md-4">
                  <Img fixed={diaspora.childImageSharp.fixed} alt="Diaspora" />
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </IntlContextConsumer>
  )
}

export default Footer
